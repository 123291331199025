// import { i18n } from '@/plugins/i18n'

export default {
  defaultObject () {
    return {
      id: null,
      name: '',
      description: '',
      unitsIds: [],
      groupsIds: [],
      validFrom: null,
      validTo: null,
      availableUsers: '',
      frequency: '',
      sections: [],
      hasLastQuestion: false,
      telemetry: {
        EBPMS: false,
        TPMS: false,
        WEIGHT: false,
        NEXTSERVICEDATE: false,
        NEXTMOT: false
      }
    }
  },
  defaultSection () {
    return {
      id: null,
      name: '',
      items: []
    }
  },
  defaultCheckItem () {
    return {
      id: null,
      name: '',
      description: '',
      checkType: 'TEXT',
      subType: '',
      options: [],
      requirePhoto: false,
      requireComment: false
    }
  }
}